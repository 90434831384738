import React, { useContext } from 'react';
import { Context } from 'store/createContext';
import { Container } from './nav.css';
import GradientLink from 'components/gradient-link';

const Nav = () => {
  const store = useContext(Context);

  return (
    <Container>
      <ul>
        <GradientLink
          className="cta"
          href={'https://www.hireacoach.com'}
          target="_blank"
          rel="noreferrer"
        >
          Hire A Coach
        </GradientLink>
      </ul>
    </Container>
  );
};

export default Nav;
